import React from "react"
import styled from "styled-components"

import Header from "../components/Header"
import Footer from "../components/Footer"
import SEO from "../components/SEO"
import "../components/styles/klaus-gridski.css"

import { Section, Wrapper } from "../components/styles/Container"
import GlobalStyles from "../components/styles/GlobalStyles"

import Dorian from "../img/scribble_light.svg"

const Paragraph = styled.p`
  max-width: 900px;
  margin-bottom: 5rem;
  /* Width in PX > 920px */
  @media only screen and (min-width: 62em) {
    text-align: center;
  }
`

const Headline = styled.h2`
  text-align: center;
  margin-bottom: 3rem;
`

const Portrait = styled.img`
  width: 300px;
  margin-bottom: 5rem;
`

const About = () => {
  return (
    <div className="app">
      <GlobalStyles />
      <SEO title="About" />
      <Header />
      <Wrapper>
        <Section className="flex vertical v-center ">
          <Portrait src={Dorian} />
          <Headline className="glitch" data-text="About Qualix">
            About Qualix
          </Headline>
          <Paragraph>
            Qualix was founded in 2020 by Dorian Quael as a one man company in
            Germany. His vision is offering professional quality audio
            engineering services independently and worldwide for fair prices.
            Dorian grew up being surrounded by classic rock music from time to
            time, coming from his father who was (and still is!) a dedicated fan
            of record players and vinyl. <br /> Dorian developed his own
            interest in music when he was around 14 years old and got into bands
            like Metallica, Linkin Park and Slipknot. Influenced by these bands
            he started learning to play the bass guitar and began to discover
            his love for music, learning to play cover songs ranging from pop
            rock to death metal. A bit later he switched over to guitar to get
            more creative and started to write his own songs, which found their
            place in his band. He went further and discovered electronic music
            production within a DAW and was soon being confronted with mixing.
            <br /> <br /> He realized that mixing is complicated and not easy to
            learn, so he focused more on his creativity and song writing. Around
            this time Dorian finished school and started to study at the
            university, but he felt over time that he wasn’t doing the right
            thing. After a few years of halfhearted studying and jobbing he
            realized that the thing he really wants to do is what he was doing
            the whole time in his leisure time: music. From there, things were
            looking up pretty fast. Looking for different types of jobs in the
            music industry Dorian got really interested in audio engineering and
            started his education at “Marburg Records Tonstudio” to become a
            certified audio engineer after two years of full time field
            experience. <br /> During this time he emerged as a very adaptive
            mixing engineer, working on many different genres. Shortly he
            started working as a freelancer on fiverr and was growing very fast,
            gaining crucial experience by finishing more than 100 projects with
            completely satisfied customers. Based on that, he was planning to
            start his very own company and a little bit later Qualix was
            founded!
          </Paragraph>
        </Section>
      </Wrapper>
      <Footer />
    </div>
  )
}

export default About
